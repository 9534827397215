.carousel {
	overflow: hidden;
    height: 90vh;
	.carousel-inner {
		height: 100%;
		.carousel-item {			
			&:nth-child(1) {
				background: url("../images/folkspice-wallpaper2.jpeg") no-repeat center center;
				background-size: cover;
			}
			&:nth-child(2) {
				background: url("../images/folkspice-wallpaper1.jpeg") no-repeat center center;
				background-size: cover;
			}
			&:nth-child(3) {
				background: url("../images/folkspice-wallpaper3.jpeg") no-repeat center center;
				background-size: cover;
			}
			&:nth-child(4) {
				background: url("../images/folkspice-wallpaper4.jpeg") no-repeat center center;
				background-size: cover;
			}
			height: 100%;
			position: relative;
			&::before {
				content: "";
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.5);
				position: absolute;
				left: 0px;
				top: 0px;
				z-index: 1;
			}
		}
		.carousel-caption {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-direction: column;
			height: 100%;
			bottom: 50px;
			z-index: 2;
			font-size: 1.25rem;
			p {
				@extend .mediumWeight;
				font-size: 75%;
				position: relative;
				padding-bottom: 7px;
				&::after {
					background: var(--color-white);
					width: 20%;
					height: 1px;
					position: absolute;
					bottom: 0px;
					left: 0px;
					content: "";
				}
			}
			h3 {
				@extend .mediumWeight;
				font-size: 150%;
				padding-top: 15px;
			}
		}
	}
	.carousel-control-next, .carousel-control-prev {
		display: inline-block;
		width: auto;
		.carousel-control-next-icon, .carousel-control-prev-icon {
			background: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			padding: 30px;
			position: relative;
			&:after, &:before {
				content: "";
				width: 15px;
				height: 1px;
				top: 35px;
    			left: 20px;
				background: var(--color-white);
				position: absolute;
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
				top: 25px;
    			left: 20px;
			}
		}
	}
	.carousel-control-prev {
		left: 5%;
	}
	.carousel-control-next {
		right: 5%;
		.carousel-control-next-icon {
			transform: rotate(180deg);
		}
	}
}